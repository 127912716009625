import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import { Layout, Listing, Wrapper, SliceZone, Title, SEO, Header } from '../components'
import Categories from '../components/Listing/Categories'
import website from '../../config/website'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faStar } from "@fortawesome/free-solid-svg-icons";
import trustimage from '../../static/img/trustpilot-vector-logo.png'
import { DiscussionEmbed } from "disqus-react"

const Hero = styled.header`
  background-color: ${props => props.theme.colors.greyLight};
  padding-top: 1rem;
  padding-bottom: 4rem;
`

const AffiliateNotice = styled.header`
font-size:.7rem
`

const Headline = styled.p`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: ${props => props.theme.colors.grey};
  font-size: 1.25rem;
  a {
    font-style: normal;
    font-weight: normal;
  }
`

const checkRates = 'Check Rates'
const applyNow =  'Apply Now'
const lock = <FontAwesomeIcon size="sm" icon={faLock} />

const FiveStar = <div style={{
  color: '#4675c3',
}}
>
  <FontAwesomeIcon size="lg" icon={faStar} />
  <FontAwesomeIcon size="lg" icon={faStar} />
  <FontAwesomeIcon size="lg" icon={faStar} />
  <FontAwesomeIcon size="lg" icon={faStar} />
  <FontAwesomeIcon size="lg" icon={faStar} />
</div>;



const PostWrapper = Wrapper.withComponent('main')

const LenderPost = ({ data: { prismicLenderreview, lenderposts }, location }) => {
  const { data } = prismicLenderreview
  let categories = false
  if (data.categories[0].category) {
    categories = data.categories.map(c => c.category.document[0].data.name)
  }
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: data.uid},
  }

  return (
    <Layout customSEO>
      <SEO
        title= {data.lender + ' Student Loan Refinance Review'}
        pathname={location.pathname}
        desc={data.lender + ' student loan refinance guide. Discover' + data.lender + ' rates for student loan consolidation.'}
        node={prismicLenderreview}
        article
      />

      <Wrapper>
          <Header />
          <nav className="level">
            <div className="level-left">
              <div className="level-item">
                <div className="field has-addons">
                  <h1 className="control title is-size-2 has-text-weight-bold is-bold-light">
                    {data.lender} <br/>Student Loan Refinancing Review
                  </h1>
                </div>
              </div>
            </div>

            <div className="level-right">
              <div className="level-item">
              <img className="lender-image-small"
                   src={data.image.url}
                   alt={data.image.alt}
              />
            </div>
            </div>
          </nav>

          <section className="box review">
            <div className="hero-body">
              <div className="container">
                <h1 className="title has-text-centered">
                  {data.lender} Student Loan Refinance Rates
                </h1>

                <nav className="level">
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">Fixed APR</p>
                      <p className="title">{data.fix_apr.text}</p>
                    </div>
                  </div>
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">Variable APR</p>
                      <p className="title">{data.var_apr.text}</p>
                    </div>
                  </div>
                </nav>

                <div className="container">
                  <div className="level-item has-text-centered">
                    <div>
                      <a href={data.lenderafflink.url} className="lenderReviewButton centered">{lock}{` `}{applyNow}</a>
                    </div>
                  </div>
                  <AffiliateNotice>
                    <div className="level-item has-text-centered">
                      <p>on {data.lender}'s secure website</p>
                    </div>
                  </AffiliateNotice>
                <h1 className="title has-text-centered">
                  LoanFuze's Rating
                </h1>
                <h2 className="subtitle has-text-centered">
                  4.5/5
                </h2>
                <h2 className="subtitle has-text-centered">
                  {FiveStar}
                </h2>
                </div>



                </div>
            </div>
          </section>
        </Wrapper>


      <PostWrapper id={website.skipNavId}>
        <section className="section">
          <div className="container content">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <blockquote className="title">{data.blurb.text}</blockquote>
              </div>
            </div>
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <h2 className="title">{data.lender} Student Loan Refinance Review:</h2>
                <React.Fragment>
                  <div dangerouslySetInnerHTML={{ __html: data.reviewpost.html }} />
                </React.Fragment>
              </div>
            </div>
            <div className="columns">
              <div className="column is-6">
                <h3>Strengths:</h3>
                <React.Fragment>
                  <div dangerouslySetInnerHTML={{ __html: data.pros.html }} />
                </React.Fragment>
              </div>
              <div className="column is-6">
                <h3>Weaknesses:</h3>
                <React.Fragment>
                  <div dangerouslySetInnerHTML={{ __html: data.cons.html }} />
                </React.Fragment>
              </div>
            </div>
          </div>

          <div className="container content">
            <div className="columns is-centered has-text-centered">
              <div className="column is-8 box">
                <div>
                  <img className="lender-image-small is-marginless"
                       src={data.image.url}
                       alt={data.image.alt}
                  />
                </div>
                <table className="table is-striped">
                  <tbody>
                  <tr>
                    <td className="has-text-weight-bold	">Fixed Rates</td>
                    <td>{data.fix_apr.text}</td>
                  </tr>
                  <tr>
                    <td className="has-text-weight-bold	">Variable Rates</td>
                    <td>{data.var_apr.text}</td>
                  </tr>
                  <tr>
                    <td className="has-text-weight-bold	">Loan Amount</td>
                    <td>{data.loan_amounts.text}</td>
                  </tr>
                  <tr>
                    <td className="has-text-weight-bold	">Loan Terms</td>
                    <td>{data.terms.text}</td>
                  </tr>
                  </tbody>
                </table>
                <div>
                  <div className="level-item has-text-centered">
                    <div>
                      <a href={data.lenderafflink.url} className="lenderReviewButton centered">{lock}{` `}{applyNow}</a>
                    </div>
                  </div>
                  <AffiliateNotice>
                    <div className="level-item has-text-centered">
                      <p>on {data.lender}'s secure website</p>
                    </div>
                  </AffiliateNotice>
                  </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container content">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <h2 className="title">Qualifications and Requirements for {data.lender} Student Loan Refinancing:</h2>
                <React.Fragment>
                  <div dangerouslySetInnerHTML={{ __html: data.qualifications.html }} />
                </React.Fragment>
                <h2 className="title">{data.lender} Loan Repayment:</h2>
                <React.Fragment>
                  <div dangerouslySetInnerHTML={{ __html: data.loan_repayment.html }} />
                </React.Fragment>
              </div>
            </div>

            <div className="container is-narrow">
              <div className="box has-shadow1">
                <div className="columns level has-text-centered">
                  <div className="column level-item">
                    <h1 className="title">Learn More</h1>
                  </div>
                  <div className="column level-item">
                    <img className="lender-image-small is-marginless"
                         src={data.image.url}
                         alt={data.image.alt}
                    />
                  </div>
                  <div className="column level-item">  <a href={data.lenderafflink.url} className="lenderReviewButton centered">{lock} {applyNow}</a>
                  </div>

                </div>
              </div>
            </div>

          </div>

          <section className="section">
            <div className="container content">
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <h2 className="title">Customer Service:</h2>
                  <React.Fragment>
                    <div dangerouslySetInnerHTML={{ __html: data.customer_service.html }} />
                  </React.Fragment>
                  <h2 className="title">Last Words on {data.lender}:</h2>
                  <React.Fragment>
                    <div dangerouslySetInnerHTML={{ __html: data.last_words.html }} />
                  </React.Fragment>
                  <h2 className="title has-text-centered">{data.lender} Overview:</h2>
                  <React.Fragment>
                    <div dangerouslySetInnerHTML={{ __html: data.overview.html }} />
                  </React.Fragment>
                </div>
              </div>
            </div>
          </section>

        <div className="has-text-centered">
          <img className="lender-image-small"
               src={data.image.url}
               alt={data.image.alt}
          />
          <div className="level-item has-text-centered">
            <div>
              <a href={data.lenderafflink.url} className="lenderReviewButton centered">{lock}{` `}{applyNow}</a>
            </div>
          </div>
          <AffiliateNotice>
            <div className="level-item has-text-centered">
              <p>on {data.lender}'s secure website</p>
            </div>
          </AffiliateNotice>
        </div>
        </section>

<section className="section">
  <div className="columns has-text-centered">
    <div className="column is-10 is-offset-1">
      <h2 className="title">Comments and Questions</h2>
      <DiscussionEmbed {...disqusConfig} />
    </div>

  </div>
      </section>


      </PostWrapper>
    </Layout>
  )
}

export default LenderPost

LenderPost.propTypes = {
  data: PropTypes.shape({
    prismicLenderreview: PropTypes.object.isRequired,
    lenderposts: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
  }).isRequired,
  location: PropTypes.object.isRequired,
}

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PostBySlugg($uid: String!) {
    prismicLenderreview(uid: { eq: $uid }) {
       uid
      first_publication_date
      last_publication_date
      data {
          lender
          loanfuzerating
          reviewpost {
            html
          }
          lenderafflink {
            url
          }
          loan_amounts {
            text
          }
          image {
            url
          }
          trust_pilot {
            url
          }
          customer_reviews {
            html
          }
          cons {
            html
          }
          pros {
            html
          }
          qualifications {
            html
          }
          fix_apr {
            text
          }
          var_apr {
            text
          }
          terms {
            text
          }
              overview {
          html
        }
        loan_repayment {
          html
        }
        last_words {
          html
        }
        customer_service {
          html
        }
        blurb {
          text
        }
        categories {
          category {
            document {
              data {
                name
              }
            }
          }
        }
        body {
          ... on PrismicLenderreviewBodyText {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicLenderreviewBodyCodeBlock {
            slice_type
            id
            primary {
              code_block {
                html
              }
            }
          }
          ... on PrismicLenderreviewBodyQuote {
            slice_type
            id
            primary {
              quote {
                html
                text
              }
            }
          }
          ... on PrismicLenderreviewBodyImage {
            slice_type
            id
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    lenderposts: allPrismicLenderreview {
    nodes {
      uid
      data {
        lender
        categories {
          category {
            document {
              data {
                name
              }
            }
          }
        }
      }
    }
  }
}
`
